
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        

































































.magazine-landing {
  &.before-onboard > * {
    opacity: 0;
  }
}
