
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        
















































































$bp: l;

.magazine-articles {
  position: relative;
}

.magazine-articles__title {
  @include mq($bp) {
    font-size: 8rem;
    line-height: 7.4rem;
  }
}

.magazine-articles__list {
  @extend %list-nostyle;
}

.magazine-articles__item {
  @include mq($bp) {
    display: flex;
    gap: col(1);

    &:nth-child(even) {
      flex-direction: row-reverse;
    }
  }
}

.magazine-articles__item__content {
  flex: 1;
}

.magazine-articles__item__card {
  position: relative;
  overflow: hidden;
  margin: $spacing * 2 $spacing * -1 0;
  border-radius: 12px;

  ::v-deep {
    .magazine-card__content {
      max-width: none;
    }
  }

  &.has-video ::v-deep {
    .magazine-card__picture {
      display: none;
    }
  }

  @include mq($bp) {
    width: col(7);
    max-width: none;
    margin: 0;

    ::v-deep {
      .magazine-card__content__category {
        font-size: 1.5rem;

        // stylelint-disable-next-line selector-max-combinators, selector-max-compound-selectors, max-nesting-depth
        .icon {
          width: 2.4rem;
          margin-right: $spacing * 0.5;
        }
      }

      .magazine-card__content__title {
        font-size: 2.5rem;
      }
    }
  }
}

.magazine-articles__item__card__video {
  @include get-all-space;

  z-index: -1;
  transition: transform 0.3s $ease-softer;

  video {
    @include image-fit;
  }

  .magazine-articles__item__card:hover & {
    transform: scale(1.05);
  }
}
