
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        














































































$bp: l;

.magazine-landing-hero {
  display: flex;
  flex-direction: column;
  height: 75vh;

  @include mq($bp) {
    position: relative;
    display: block;
    overflow: hidden;
    width: 100%;
    height: auto;
    max-height: 80vh;
    aspect-ratio: 5/4;

    &::before {
      @include get-all-space;

      content: '';
      z-index: -1;
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.5) 0%,
        rgba(0, 0, 0, 0) 50%
      );
      transition: opacity 1s;
    }

    .before-onboard &::before {
      opacity: 0;
    }
  }
}

.magazine-landing-hero-inner {
  display: flex;
  flex-direction: column;

  @include mq($until: $bp) {
    padding-top: 8rem;
    padding-right: 0 !important; // stylelint-disable-line declaration-no-important
    padding-left: 0 !important; // stylelint-disable-line declaration-no-important
  }

  @include mq($bp) {
    height: 100%;
    padding-bottom: 11rem;
  }
}

.magazine-landing-hero__title {
  @extend %text-center;

  color: $c-mustard;
  line-height: 1.8;

  @include mq($bp) {
    position: relative;
    z-index: 1;
    margin-top: $spacing * 0.5;
    color: $c-white;
    font-size: 8rem;
  }
}

.magazine-landing-hero__text {
  @extend %text-center;

  flex: 1;
  max-width: 30em;
  margin: auto;
  padding: 0 $spacing;
  font-size: 1.8rem;
  line-height: 2.5rem;

  @include mq($bp) {
    color: $c-white;
  }
}

.magazine-landing-hero__scroll-outer {
  position: relative;
}

.magazine-landing-hero__scroll {
  @extend %button-nostyle;
  @extend %ff-alt;
  @extend %fw-bold;
  @extend %text-center;
  @extend %text-uppercase;

  position: absolute;
  z-index: 1;
  right: 0;
  bottom: 0;
  display: none;
  width: 15rem;
  height: 15rem;
  margin-left: auto;
  padding-top: $spacing * 0.75;
  color: $c-mustard;
  font-size: 1.8rem;
  background: $c-white;
  border-radius: 50%;
  box-shadow: $card-shadow;
  transition: transform 0.3s $ease-softer;

  .icon {
    transition: 0.2s $ease-softer;
  }

  @include mq($bp) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &:hover,
    &:focus-visible {
      transform: scale(1.05);

      .icon {
        transform: translateY(5px);
      }
    }
  }
}

.magazine-landing-hero__picture {
  position: relative;
  flex: 1;
  margin-top: $spacing;

  @include mq($bp) {
    @include center-x;

    z-index: -2;
    top: 0;
    overflow: hidden;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    aspect-ratio: 5/4;

    .before-onboard & {
      height: auto;
      aspect-ratio: 5/4;
    }
  }
}
